<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="getReportData"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="6"
            md="9"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                v-if="userData.group_id === 90"
                v-model="selectUser"
                :options="userList"
                style="min-width:120px"
                class="small p-0 mr-50 w-25"
                label="fullname"
                placeholder="Agent"
                @input="updateData"
              />
              <b-form-datepicker
                id="vi-from"
                v-model="from"
                :max="until"
                :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                size="sm"
                required
                name="from"
                class="w-25 mr-50"
                placeholder="From"
              />
              <b-form-datepicker
                id="vi-until"
                v-model="until"
                :min="from"
                :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                size="sm"
                required
                name="until"
                class="w-25 mr-50"
                placeholder="Until"
              />
              <b-button
                type="submit"
                variant="primary"
                size="sm"
                @click="getReportData"
              >
                <feather-icon
                  size="14"
                  icon="SearchIcon"
                />
              </b-button>
              <b-button
                type="submit"
                variant="outline-danger"
                size="sm"
                class="ml-50"
                @click="downloadData('pdf')"
              >
                <feather-icon
                  size="14"
                  icon="DownloadIcon"
                />
                pdf
              </b-button>
              <b-button
                type="submit"
                variant="outline-success"
                size="sm"
                class="ml-50"
                @click="downloadData('xls')"
              >
                <feather-icon
                  size="14"
                  icon="DownloadIcon"
                />
                xls
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refReportListTable"
        class="position-relative"
        :items="reportList"
        responsive
        :fields="tableColumns"
        primary-key="faq_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="getReportData"
      >
        <!-- Column: Created At -->
        <template #cell(ticket_number)="data">
          <b-link :to="{ name: 'tickets-view', params: { id: data.item.ticket_number } }">
            {{ data.item.ticket_number }}
          </b-link>
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | formatDate }}
        </template>

        <template #cell(firstreply)="data">
          <template v-if="data.item.firstreply">
            {{ data.item.firstreply.created_at | formatDate }}
          </template>
          <template v-else>
            Data not found.
          </template>
        </template>

        <template #cell(diff)="data">
          <div
            :class="(data.item.diff.hours < data.item.diff.sla) ? 'text-success' : 'text-danger'"
          >
            {{ data.item.diff.diff.mins + 'M' }}
          </div>
        </template>

        <template #cell(sla)="data">
          <template v-if="data.item.sla">
            <b-badge
              :variant="(data.item.sla.hours < 3) ? 'light-success' : 'light-danger'"
            >
              <template v-if="data.item.sla.hours < 3">
                SLA Achieved
              </template>
              <template v-else>
                SLA Not Achieved
              </template>
            </b-badge>
          </template>
          <template v-else>
            Data not found.
          </template>
        </template>

        <!-- Column: Tags -->
        <template #cell(tag)="data">
          <b-badge
            pill
            variant="light-info"
            class="text-capitalize mr-25 mb-25"
          >
            {{ data.item.tag }}
          </b-badge>
        </template>

        <template #cell(subtag)="data">
          <b-badge
            pill
            variant="light-info"
            class="text-capitalize mr-25 mb-25"
          >
            {{ data.item.subtag }}
          </b-badge>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormDatepicker, BButton, BTable, BPagination, BBadge, BLink,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import moment from 'moment'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Notification

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BButton,
    BTable,
    BPagination,
    BBadge,
    BLink,

    vSelect,
  },
  setup() {
    const toast = useToast()
    const isAddNewReportSidebarActive = ref(false)

    // Table Handlers
    const tableColumns = [
      { key: 'ticket_number' },
      { key: 'channel' },
      { key: 'interaksi', label: 'Jenis Interaksi' },
      { key: 'agent' },
      { key: 'author', label: 'Pemohon' },
      { key: 'company', label: 'Perusahaan' },
      { key: 'kasus' },
      { key: 'penanganan' },
      { key: 'keterangan' },
      { key: 'tag', label: 'Tag' },
      { key: 'subtag', label: 'Sub Tag' },
      { key: 'status' },
      { key: 'created_at', label: 'Tiket Dibuat' },
      { key: 'firstreply', label: 'Respon Pertama' },
      { key: 'diff', label: 'Durasi' },
      { key: 'sla', label: 'SLA' },
    ]

    const perPage = ref(25)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    // const searchQuery = ref('')
    const sortBy = ref('ticket_id')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)

    const userData = getUserData()

    return {
      toast,
      userData,
      // Sidebar
      isAddNewReportSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      //   searchQuery,
      sortBy,
      isSortDirDesc,

      // Extra Filters
      roleFilter,
    }
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const lweek = new Date(today.getFullYear(), today.getMonth(), 1)

    return {
      from: moment(lweek).format('YYYY-MM-DD'),
      until: moment(today).format('YYYY-MM-DD'),
      reportList: [],
      userList: [],
      selectUser: '',
      dataMeta: '',
    }
  },
  created() {
    this.getReportData()
  },
  mounted() {
    if (this.userData.group_id === 90) {
      this.getUserList()
    }
  },
  methods: {
    updateData(data) {
      if (data === null) {
        this.selectUser = ''
        this.getReportData()
      }
    },
    onPageChange(params) {
      window.scrollTo(0, 0)
      this.currentPage = params
      this.getReportData()
    },
    getUserList() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        type: 100,
      }
      const target = '/report/user'
      this.$http.post(target, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'
            this.userList = resp.data
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching User Reports',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getReportData() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        length: this.perPage,
        from: this.from,
        until: this.until,
      }
      let target = '/report'
      if (this.currentPage !== 1) {
        target += `?page=${this.currentPage}`
      }
      if (this.userData.group_id === 90 && this.selectUser !== '') {
        params.agent_id = this.selectUser.id
      }
      this.$http.post(target, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.reportList = resp.data.data
            this.dataMeta = resp.data.meta
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching User Reports',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    downloadData(mode) {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        from: this.from,
        until: this.until,
        type: mode,
      }
      if (this.userData.group_id === 90 && this.selectUser !== '') {
        params.agent_id = this.selectUser.id
      }
      this.$http.post('/report/download', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Downloading...',
                icon: 'AlertTriangleIcon',
                variant: 'info',
              },
            })

            const link = document.createElement('a')
            link.href = resp.data.file
            link.download = resp.data.file
            link.target = '_blank'
            link.click()
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching User Reports',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
